import * as yup from 'yup';
import { Country, getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input';

export const isValidPhoneNo = (phoneNo: string): boolean => {
    if (!phoneNo || phoneNo?.length < 12) {
        return false;
    }

    return !!parsePhoneNumber(phoneNo)?.country;
};

export const PHONE_NUMBER_VALIDATION = (
    countryCode = 'US',
): yup.StringSchema => {
    const max = countryCode === 'US' ? 12 : 18;
    const maxLength = countryCode === 'US' ? 10 : 15;

    const callingCode = getCountryCallingCode(countryCode as Country);

    return yup
        .string()
        .required('Phone number is required')
        .max(max, `Phone number must be of atmost ${maxLength} digits`)
        .min(
            11 + callingCode.toString().length,
            'Phone number must be of atleast 10 digits',
        ) // now we are also including calling code
        .test('isValidPhoneNo', 'This is not a valid Phone Number', (value) => {
            if (value) {
                return isValidPhoneNo(value);
            }
            return true;
        });
};