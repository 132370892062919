import React from "react";
import 'react-phone-number-input/style.css'
import PhoneInput from "react-phone-number-input";

type PhoneNumberInputProps = {
    value: string;
    error?: string;
    touched?: boolean;
    onChange: (e: any) =>  void;
    onCountryChange: (e: any) =>  void;
    onBlur?: (e: any) =>  void;
};

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({value, touched, error, onChange, onCountryChange, onBlur}) => {
    return (
        <div className="flex flex-col w-full p-2">
            <PhoneInput
                className="phone-number-input outline-none p-2 rounded-none border border-gray-300"
                placeholder="Phone number"
                value={value}
                defaultCountry="US"
                onChange={onChange}
                onCountryChange={onCountryChange}
                onBlur={onBlur}
            />
            {touched && error && <span className="text-red-500 ">{error}</span>}
        </div>
    )
}

export default PhoneNumberInput;