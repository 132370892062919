import React, { InputHTMLAttributes } from "react";

interface FormInputProps extends InputHTMLAttributes<any> {
  error?: string;
  touched?: boolean;
}

const FormInput: React.FC<FormInputProps> = ({
  error,
  touched,
  className,
  placeholder,
  ...rest
}) => {
  return (
    <div className="flex flex-col w-full p-2">
      <input
        placeholder={placeholder}
        className={`outline-none p-4 rounded-lg border border-black placeholder:text-neutral-600  ${className}`}
        {...rest}
      />
      {touched && !!error && <span className="text-red-500 ">{error}</span>}
    </div>
  );
};

export default FormInput;
