import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { ENV_GATSBY_SITE_URL } from "../utils/secrets";

interface Props {
  title?: string;
  keywords?: string[];
  pageUrl: string;
  description?: string;
  lang?: string;
  meta?: [];
  imageUrl?: string;
  isArticle?: boolean;
  author?: string;
  useSiteUrlForImage?: boolean;
  richData?: { [key: string]: any };
}

const SEO: React.FC<Props> = (props: Props) => {
    const {
    title,
    description,
    lang,
    meta,
    pageUrl,
    imageUrl,
    isArticle = false,
    author,
    useSiteUrlForImage = true,
    richData,
  } = props;

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteName
            description
            keywords
            author
            facebookAppId
            twitterHandle
            siteUrl
          }
        }
      }
    `
  );

  const metaTitle = title || site.siteMetadata.siteName;
  const metaDescription = description || site.siteMetadata.description;

  let keywords = props.keywords;
  if (!keywords || (Array.isArray(keywords) && keywords.length === 0)) {
    keywords = site.siteMetadata.keywords;
  }

  const metaKeywords = Array.isArray(keywords) ? keywords.join(", ") : keywords; // keywords is either array or string

  const metaPageUrl = ENV_GATSBY_SITE_URL + pageUrl;

  const facebookTags = [
    {
      property: `og:url`,
      content: metaPageUrl,
    },
    {
      property: `og:site_name`,
      content: site.siteMetadata.siteName,
    },
    {
      property: `og:title`,
      content: metaTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    // {
    //   property: `og:image`,
    //   content: metaImageUrl,
    // },
    {
      property: `og:type`,
      content: isArticle ? "article" : "website",
    },
    {
      property: `og:article:author`,
      content: author,
    },
    {
      property: `og:locale`,
      content: lang,
    },
    {
      property: `fb:app_id`,
      content: site.siteMetadata.facebookAppId,
    },
  ];

  const twitterTags = [
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:site`,
      content: site.siteMetadata.twitterHandle,
    },
    {
      name: `twitter:site:id`,
      content: site.siteMetadata.twitterId,
    },
    {
      name: `twitter:creator`,
      content: author,
    },
    {
      name: `twitter:title`,
      content: metaTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    // {
    //   name: `twitter:image`,
    //   content: metaImageUrl,
    // },
    // {
    //   name: `twitter:image:src`,
    //   content: metaImageUrl,
    // },
  ];

  const pinterestTags = [
    {
      name: `article:author`,
      content: author,
    },
  ];

  const allMeta = [
    {
      name: "description",
      content: metaDescription,
    },
    {
      name: "keywords",
      content: metaKeywords,
    },
    ...facebookTags,
    ...twitterTags,
    ...pinterestTags,
  ].filter(tag => !!tag.content);

  return (
    <Helmet
      htmlAttributes={{ lang: site.siteMetadata.siteLanguage }}
      title={metaTitle}
      meta={allMeta}
    >
      {!!richData && (
        <script type="application/ld+json">{JSON.stringify(richData)}</script>
      )}
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

export default React.memo(SEO);