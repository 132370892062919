import { Formik } from "formik";
import React, { useState } from "react";
import FormInput from "../components/FormInput";
import PageWrapper from "../components/PageWrapper";
import SEO from "../components/SEO";
import { ROUTE_LINKS } from "../utils/routes";
import Spinner from "../images/spinner.gif";
import Success from "../images/success.png";
import * as yup from "yup";
import { landLordSignup } from "../services/apiService";
import Button from "../components/Button";
import PhoneNumberInput from "../components/PhoneNumberInput";
import { PHONE_NUMBER_VALIDATION } from "../utils/form-validation.util";

interface FormValues {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  company_name: string;
  receiver_type: string;
}

const SignupPage = () => {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [selectedCountry, setSelectedCountry] = useState('US');

  const signUpValidationSchema = yup.object().shape({
    first_name: yup
      .string()
      .min(3, "First Name must be at least 3 characters long")
      .required("First Name is required"),
    last_name: yup
      .string()
      .min(3, "Last Name must be at least 3 characters long")
      .required("Last Name is required"),
    email: yup
      .string()
      .email("This is not a valid email")
      .required("Email is Required"),
    company_name: yup
      .string()
      .min(3, "Company Name must be at least 2 characters long")
      .required("Company Name is Required"),
    phone_number: PHONE_NUMBER_VALIDATION(selectedCountry)
  });

  const onSubmit = async (formValues, { setSubmitting, resetForm, setErrors }) => {
    setError("");
    setSuccess("");
    setSubmitting(true);
    const data: FormValues = {
      first_name: formValues.first_name,
      last_name: formValues.last_name,
      email: formValues.email,
      phone_number: formValues.phone_number,
      company_name: formValues.company_name,
      receiver_type: "landlord",
    };
    try {
      const response = await landLordSignup(data);
      if (response.success) {
        setSubmitting(false);
        setSuccess("Success");
        resetForm();
      } else {
        setSubmitting(false);
        setError("Error");
      }
    } catch (e: any) {
      const { errors } = e?.response?.data;
      if (errors) {
        if (errors.hasOwnProperty('invite_data.email') && errors['invite_data.email'][0].includes('already exists')) {
          setErrors({
            email: 'Email not available. Please use a different email or contact support.'
          })
        }
      }
    }
  };
  return (
    <PageWrapper path={ROUTE_LINKS.signup}>
      <SEO pageUrl={ROUTE_LINKS.signup} />
      <main className="max-w-6xl md:mx-auto p-2.5 pb-12">
        <div>
          <div className="border border-gray-200">
            <Formik
              initialValues={{
                first_name: "",
                last_name: "",
                email: "",
                phone_number: "",
                company_name: "",
              }}
              validationSchema={signUpValidationSchema}
              onSubmit={onSubmit}
              validateOnMount
            >
              {({
                values,
                handleChange,
                errors,
                touched,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
                setFieldValue,
                setFieldTouched
              }) => {
                return (
                  <>
                    {error || success ? (
                      <div>
                        {!!error && (
                          <div className="px-4 py-1 mx-2 my-8 font-light text-center border-red-500 border-2 ">
                            {error}
                          </div>
                        )}
                        {!!success && (
                          <div className="px-4 py-1 mx-2 my-8 font-light text-center">
                            <img
                              src={Success}
                              alt=""
                              className="mx-auto h-24 w-24"
                            />
                            <span className="text-4xl">{success}</span>
                            <p className="text-xl">
                              Thank you for showing your interest in Matrix. You will be receiving a sign up link on your email soon.
                            </p>
                          </div>
                        )}
                      </div>
                    ) : (
                      <form onSubmit={handleSubmit} className="">
                        <h3 className="text-2xl font-semibold text-center p-3">
                          Sign up as Landlord
                        </h3>
                        <div className="m-2.5">
                          <FormInput
                            touched={touched.first_name}
                            error={errors.first_name}
                            type="text"
                            name={"first_name"}
                            value={values.first_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={"First Name"}
                            className="!border-gray-300 !rounded-none focus:border-orange-400 !p-2"
                          />
                          <FormInput
                            touched={touched.last_name}
                            error={errors.last_name}
                            type="text"
                            name={"last_name"}
                            value={values.last_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={"Last Name"}
                            className="!border-gray-300 !rounded-none focus:border-orange-400 !p-2"
                          />
                          <FormInput
                            touched={touched.email}
                            error={errors.email}
                            type="email"
                            name={"email"}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={"Email Address"}
                            className="!border-gray-300 !rounded-none focus:border-orange-400 !p-2"
                          />
                          <PhoneNumberInput
                            value={values.phone_number}
                            error={errors.phone_number}
                            touched={touched.phone_number}
                            onChange={(value) => {
                              setFieldValue('phone_number', value);
                            }}
                            onCountryChange={setSelectedCountry}
                            onBlur={() => setFieldTouched('phone_number', true, true)}
                          />
                          <FormInput
                              touched={touched.company_name}
                              error={errors.company_name}
                              type="text"
                              name={"company_name"}
                              value={values.company_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder={"Company Name"}
                              className="!border-gray-300 !rounded-none focus:border-orange-400 !p-2"
                          />
                          <div className="flex justify-center pt-4 mt-3 items-center">
                            <Button
                              type="submit"
                              className="px-16 py-3 capitalize font-bold text-white bg-primary w-full md:w-auto mx-2  flex justify-center items-center"
                              disabled={!isValid}
                            >
                              Request sign up link
                            </Button>
                            {isSubmitting && (
                              <img
                                src={Spinner}
                                alt="Spinner"
                                className="h-8 w-8"
                              />
                            )}
                          </div>
                        </div>
                      </form>
                    )}
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </main>
    </PageWrapper>
  );
};

export default React.memo(SignupPage);
